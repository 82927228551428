import { PermissionApp } from '@emrm/core/permissions/types';

export interface PermissionsStoreState {
	permissions: PermissionApp[] | null;
	loading: boolean;
	error: string | null;
}

export const initialState: PermissionsStoreState = {
	permissions: null,
	loading: false,
	error: null,
};
